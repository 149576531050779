@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
a {
  color: rgba(252, 184, 39, 1);
}
